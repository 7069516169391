/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */

import React, { Component } from "react";
import { Form, notification, Checkbox } from "antd";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Radio } from "antd";
import API from "api/api";
import { RAZORPAY_CREDENTIALS, RAZORPAY_ENV } from "utilities/constants";
import { languageLable } from "utilities/helpers";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import { Grid } from "@mui/material/";
import swal from "sweetalert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

var api;

class Payment extends Component {
  constructor(props) {
    super(props);
    api = new API();
    this.state = {
      method: 0,
      paymentModes: [],
      checkoutData: null,
      shippingMode: null,
      coupandiscount: null,
      coupandiscountTotal: 0,
      loadingStart: false,
      value: 0,
      walletamount: 0,
      walletCashback: 0,
      walletbalance: 0,
      walletvalue: "",
      coupancode: null,
      name: "",
      offerList: [],
      isCouponVisible: false,
      couponCodeTemp: "",
      couponTitleTemp: "",
      offerpaymentModes: [],
      open: false,
      temp: "",
      open1: false,
      open2: false,
      isError: "",
      documentVal: "",
      urlLink: "",
      loading: false,
      method2: 0,
      loyaltypoints: 0,
      redeempoints: 0,
      refresh: false,
      // paymentProvider: ''
    };
  }

  componentDidMount = async () => {
    try {
      if (this.props.history.location.state.couponcode !== "") {
        this.setState({
          couponCodeTemp: this.props.history.location.state.couponcode,
        });
        this.setState({
          couponTitleTemp: this.props.history.location.state.offertitle,
        });
        this.setState({
          offerpaymentModes:
            this.props.history.location.state.PaymentModes.sort(),
        });
        if (this.state.isCouponVisible) {
          this.setState({ isCouponVisible: true });
        } else {
          this.setState({ isCouponVisible: false });
        }
        this.setState({
          method2: this.props.history.location.state.PaymentModes[0],
        });
      }
    } catch (e) {
    } finally {
      api.get("GetCheckout").then((response) => {
        if (response.StatusCode === 200 && response.Result) {
          this.setState({
            checkoutData: response.Result,
            walletbalance: response.Result.WalletBalance,
            walletamount: response.Result.WalletAmount,
          });
        }
      });
      this.getPaymentModes();
      this.getShippingMode();
      this.renderPlaceOrderButton();
      this.renderWalletCheckBox();
      this.handleGetLoyaltyPoints();
    }
  };

  // componentDidUpdate = async() =>{
  //   this.setState({refresh:!this.state.refresh});
  // }

  getPaymentModes = () => {
    const locationId =
      this.props.auth &&
      this.props.auth.defaultAddress &&
      this.props.auth.defaultAddress.BusinessCustomerAddressMapping &&
      this.props.auth.defaultAddress.BusinessCustomerAddressMapping.LocationId;

    if (locationId) {
      api.get(`GetPaymentModes?locationId=${locationId}`).then((response) => {
        if (response.StatusCode === 200 && response.Result) {
          let t = response.Result.sort();

          this.setState({
            paymentModes: t,
            method: t[0],
            // method: response.Result[1],
          });
        }
      });
    }
  };

  getShippingMode = () => {
    const locationId =
      this.props.auth &&
      this.props.auth.defaultAddress &&
      this.props.auth.defaultAddress.BusinessCustomerAddressMapping &&
      this.props.auth.defaultAddress.BusinessCustomerAddressMapping.LocationId;
    if (locationId) {
      api.get(`GetShippingMode?locationId=${locationId}`).then((response) => {
        if (response.StatusCode === 200 && response.Result) {
          this.setState({
            shippingMode: response.Result,
          });
        }
      });
    }
  };

  handleChangePaymentMethod = (e) => {
    this.setState({ method: e.target.value });
  };
  handleChangePaymentMethod1 = (e) => {
    this.setState({ method2: e.target.value });
  };

  handleClearCart = () => {
    api.delete(`ClearCart`).then((response) => {
      if (response.StatusCode === 200 && response.Result) {
        this.props.history.push("/account/orders");
      }
    });
  };

  handleSubmitDocument = () => {
    this.setState({ loading: true });
    try {
      if (this.state.documentVal !== "") {
        api
          .get(
            `ProceedTopaymentUsingGateway?document=${this.state.documentVal}`
          )
          .then((response) => {
            if (response.StatusCode === 200 && response.Result) {
              this.openCheckout(response.Result);
            }
          });
      } else {
        alert("Please fill document.");
      }
    } catch (e) {
      alert(e);
    }
  };

  handleContinue = () => {
    const { selectedLanguageData } = this.props.app;
    const { checkoutData } = this.state;

    this.setState({ loadingStart: true });
    if (
      (this.state.method === "Online" || this.state.method2 === "Online") &&
      checkoutData &&
      checkoutData.TotalAmount > 0
    ) {
     // const PaymentProvider = localStorage.getItem("paymentProvider");
      // alert(PaymentProvider)
      //if (PaymentProvider === "Razorpay" || PaymentProvider === "Ceilo") {
        try {
          api
            .get(`ProceedTopaymentUsingGateway?isFromWebApp=true`)
            .then((response) => {
              if (response.StatusCode === 200 && response.Result) {
                this.openCheckout(response.Result);
              }
            });
        } catch (e) {
          notification.info({
            message: languageLable(
              selectedLanguageData,
              "Something went wrong! Please try again later."
            ),
          });
          console.error("Error.....in ProceedTopaymentUsingGateway", e);
        }
      //}
    } else if (
      this.state.method === "COD" &&
      checkoutData &&
      checkoutData.TotalAmount > 0
    ) {
      api.get(`ProceedTopaymentUsingCOD`).then((response) => {
        if (response.StatusCode === 200 && response.Result) {
          this.handleClearCart();
          notification.success({
            message: languageLable(
              selectedLanguageData,
              "Order has been placed successfully."
            ),
          });
        }
      });
    } else if (
      (this.state.method === "Wallet" || this.state.method2 === "Wallet") &&
      checkoutData &&
      checkoutData.TotalAmount <= 0
    ) {
      api.get(`ProceedTopaymentUsingWallet`).then((response) => {
        if (response.StatusCode === 200 && response.Result) {
          this.handleClearCart();
          notification.success({
            message: languageLable(
              selectedLanguageData,
              "Order has been placed successfully."
            ),
          });
        }
      });
    } else {
      notification.info({
        message: languageLable(
          selectedLanguageData,
          "No payment method found!. Please contact support."
        ),
      });
    }
  };

  openCheckout = (transactionData) => {
    const { checkoutData } = this.state;
    const { userData } = this.props.auth;
    const { selectedLanguageData } = this.props.app;
    if (
      transactionData === undefined ||
      transactionData === null ||
      transactionData.RedirectURL === undefined ||
      transactionData.RedirectURL === null
    ) {
      let options = {
        key: RAZORPAY_CREDENTIALS[RAZORPAY_ENV].key,
        amount: checkoutData.TotalAmount * 100, // 2000 paise = INR 20, amount in paisa
        name: RAZORPAY_CREDENTIALS.MERCHANT_NAME,
        handler: (response) => {
          if (response.razorpay_payment_id) {
            this.razorPayConfirmPayment(
              transactionData.TransactionId,
              response.razorpay_payment_id
            );
          } else {
            notification.error({
              message: "Error",
              description: languageLable(
                selectedLanguageData,
                "Something Went wrong. Please try after sometime."
              ),
            });
          }
        },
        prefill: {
          name: userData && userData.name,
          email: userData && userData.email,
          contact: userData && userData.phonenumber,
        },
        theme: {
          color: "#2c5662",
        },
      };
      let rzp = new window.Razorpay(options);
      rzp.open();
    } else {
      // alert(transactionData.RedirectURL)
      this.handleClickOpen2();
      this.setState({ urlLink: transactionData.RedirectURL });
      // <a href="https://www.w3schools.com" target="iframe_a">W3Schools.com</a>
      // window.open(transactionData.RedirectURL)
    }
  };

  razorPayConfirmPayment = (transactionId, razorPaymentId) => {
    const { selectedLanguageData } = this.props.app;
    api
      .get(
        `PaymentConfirm?transactionId=${transactionId}&razorPaymentId=${razorPaymentId}`
      )
      .then((response) => {
        if (response.StatusCode === 200 && response.Result) {
          this.handleClearCart();
          notification.success({
            message: languageLable(
              selectedLanguageData,
              "Order has been placed successfully."
            ),
          });
        }
      });
  };

  handleCoupanCode = (values) => {
    const { selectedLanguageData } = this.props.app;

    const userDetails = localStorage.getItem("defaultAddress");
    const locationIdJsonParse = JSON.parse(userDetails);
    const locationId =
      locationIdJsonParse.BusinessCustomerAddressMapping.LocationId;

    api
      .get(
        `ApplyOffer?offerId=&couponCode=${this.state.couponCodeTemp}&locationId=${locationId}`
      )
      .then((response) => {
        if (response.StatusCode === 200 && response.Result) {
          if (response.Result.OfferViewModel) {
            this.setState({
              checkoutData: response.Result,
              walletbalance: response.Result.WalletBalance,
              walletamount: response.Result.WalletAmount,
              coupandiscount: response.Result.OfferDiscount,
              coupancode: response.Result.OfferViewModel.CoponCode,
              walletCashback: response.Result.WalletCashback,
            });
          } else {
            notification.error({
              message: languageLable(
                selectedLanguageData,
                "This Coupon code is not available!"
              ),
            });
          }
        } else {
        }
      });
  };

  handleHtmlControlChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    const { selectedLanguageData } = this.props.app;

    const userDetails = localStorage.getItem("defaultAddress");
    const locationIdJsonParse = JSON.parse(userDetails);
    const locationId =
      locationIdJsonParse.BusinessCustomerAddressMapping.LocationId;

    event.preventDefault();
    api
      .get(
        `ApplyOffer?offerId=&couponCode=${this.state.name}&locationId=${locationId}`
      )
      .then((response) => {
        if (response.StatusCode === 200 && response.Result) {
          if (response.Result.OfferViewModel) {
            this.setState({
              checkoutData: response.Result,
              walletbalance: response.Result.WalletBalance,
              walletamount: response.Result.WalletAmount,
              coupandiscount: response.Result.OfferDiscount,
              coupancode: response.Result.OfferViewModel.CoponCode,
              walletCashback: response.Result.WalletCashback,
            });
          } else {
            notification.error({
              message: languageLable(
                selectedLanguageData,
                "This Coupon code is not available!"
              ),
            });
          }
        } else {
          console.error(response.Result);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleCoupanCodeRemove = () => {
    api.get("RemoveOffer").then((response) => {
      if (response.StatusCode === 200 && response.Result) {
        this.setState({
          checkoutData: response.Result,
          isCouponVisible: false,
        });
      }
    });
  };

  renderRemoveCoupanCode = () => {
    const userDetails = localStorage.getItem("defaultAddress");
    const locationIdJsonParse = JSON.parse(userDetails);
    const locationId =
      locationIdJsonParse.BusinessCustomerAddressMapping.LocationId;

    api.get(`GetMyOffers?locationId=${locationId}`).then((response) => {
      if (response.StatusCode === 200 && response.Result) {
        this.setState({
          offerList: response.Result,
        });
      }
    });
  };

  renderToPayAmount = () => {
    const { checkoutData } = this.state;
    const currencySetting = localStorage.getItem("currency");
    const { selectedLanguageData } = this.props.app;

    if (this.state.coupandiscountTotal > 0) {
      return (
        <figure className="ps-block__total">
          <h3>
            {languageLable(selectedLanguageData, "To Pay")}
            <strong>
              {currencySetting ? currencySetting : "₹"}{" "}
              {checkoutData && checkoutData.OfferDiscount
                ? checkoutData.OfferDiscount.toFixed(2)
                : 0}
            </strong>
          </h3>
        </figure>
      );
    } else {
      return (
        <figure className="ps-block__total">
          <h3>
            {languageLable(selectedLanguageData, "Payable Amount")}
            <strong>
              {currencySetting ? currencySetting : "₹"}{" "}
              {checkoutData && checkoutData.TotalAmount
                ? (
                    checkoutData.TotalAmount + checkoutData.TotalTaxAmount
                  ).toFixed(2)
                : 0}
            </strong>
          </h3>
        </figure>
      );
    }
  };

  renderToWalletCashback = () => {
    const { checkoutData } = this.state;
    const currencySetting = localStorage.getItem("currency");
    const { selectedLanguageData } = this.props.app;

    return (
      <figure>
        <figcaption>
          <strong>
            {languageLable(selectedLanguageData, "Cashback Availed")}
          </strong>
          <strong>
            {currencySetting ? currencySetting : "₹"}{" "}
            {checkoutData && checkoutData.WalletCashback
              ? checkoutData.WalletCashback.toFixed(2)
              : 0}
          </strong>
        </figcaption>
      </figure>
    );
  };

  renderPlaceOrderButton = () => {
    const { checkoutData } = this.state;
    const { selectedLanguageData } = this.props.app;
    if (this.state.loadingStart) {
      return (
        <div className="button-container">
          <strong>
            {languageLable(selectedLanguageData, "Please wait...")}
          </strong>
        </div>
      );
    } else {
      if (this.state.coupandiscountTotal > 0) {
        return (
          <div className="button-container">
            <button
              className="ps-btn"
              onClick={(event) => {
                this.handleContinue();
                event.target.style.display = "none";
              }}
            >
              {languageLable(selectedLanguageData, "Place Order")}
            </button>
          </div>
        );
      } else {
        if (checkoutData && checkoutData.Total > 0) {
          return (
            <div className="button-container">
              <button
                className="ps-btn"
                onClick={(event) => {
                  this.handleContinue();
                  event.target.style.display = "none";
                }}
              >
                {languageLable(selectedLanguageData, "Place Order")}
              </button>
            </div>
          );
        }
      }
    }
  };

  onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  handleWalletAmount = (e) => {
    const { checkoutData } = this.state;
    if (e.target.checked === true) {
      e.target.id = checkoutData.Total;
    }
    api
      .get(
        `UseWalletAmount?offerId=&walletUsed=${e.target.checked}&walletAmount=${e.target.id}`
      )
      .then((response) => {
        if (response.StatusCode === 200 && response.Result) {
          this.setState({
            checkoutData: response.Result,
            walletbalance: response.Result.WalletBalance,
            walletamount: response.Result.WalletAmount,
          });
        }
      });
  };

  renderWalletCheckBox = () => {
    const { selectedLanguageData } = this.props.app;
    const currencySetting = localStorage.getItem("currency");

    // eslint-disable-next-line no-unused-vars
    const { checkoutData, walletamount, walletbalance } = this.state;
    if (checkoutData) {
      if (checkoutData.WalletBalance > 0 && checkoutData.WalletAmount !== 0) {
        return (
          <figure>
            {checkoutData && checkoutData.WalletCashback ? (
              <figcaption>
                <strong>
                  {languageLable(selectedLanguageData, "Cashback Availed")}
                </strong>
                <strong>
                  {" "}
                  {currencySetting ? currencySetting : "₹"}{" "}
                  {checkoutData && checkoutData.WalletCashback
                    ? checkoutData.WalletCashback.toFixed(2)
                    : 0}
                </strong>
              </figcaption>
            ) : (
              <></>
            )}
            <Checkbox
              onChange={(e) => this.handleWalletAmount(e)}
              id={
                this.state.walletamount && this.state.walletamount
                  ? this.state.walletamount
                  : 0
              }
              defaultChecked={true}
            >
              {languageLable(selectedLanguageData, "Use Wallet")} (
              {languageLable(selectedLanguageData, "Total Balance")}:{" "}
              {currencySetting ? currencySetting : "₹ "}
              {this.state.walletbalance && this.state.walletbalance
                ? this.state.walletbalance
                : 0}
              )
            </Checkbox>
          </figure>
        );
      } else {
        return (
          <figure>
            {checkoutData && checkoutData.WalletCashback ? (
              <figcaption>
                <strong>
                  {languageLable(selectedLanguageData, "Cashback Availed")}
                </strong>
                <strong>
                  {": "} {currencySetting ? currencySetting : "₹"}
                  {checkoutData && checkoutData.WalletCashback
                    ? checkoutData.WalletCashback.toFixed(2)
                    : 0}
                </strong>
              </figcaption>
            ) : (
              <></>
            )}
            <Checkbox
              onChange={(e) => this.handleWalletAmount(e)}
              id={
                this.state.walletamount && this.state.walletamount
                  ? this.state.walletamount
                  : 0
              }
            >
              {languageLable(selectedLanguageData, "Use Wallet")} (
              {languageLable(selectedLanguageData, "Total Balance")}:{" "}
              {currencySetting ? currencySetting : "₹ "}
              {this.state.walletbalance && this.state.walletbalance
                ? this.state.walletbalance
                : 0}
              )
            </Checkbox>
          </figure>
        );
      }
    }
  };

  editWalletAmount = () => {
    this.handleClickOpen();
    // this.setState({walletvalue:this.state.walletbalance})
    this.setState({ walletvalue: this.state.walletbalance });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClickOpen1 = () => {
    this.setState({ open1: true });
  };

  handleClickOpen2 = () => {
    this.setState({ open2: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClose1 = () => {
    this.setState({ open1: false });
  };

  handleClose2 = () => {
    this.setState({ open2: false });
    this.props.history.replace("/account/orders");
  };

  handleChangeValue = (e) => {
    this.setState({ walletvalue: e.target.value });
    this.setState({ temp: e.target.value });
  };

  handleOk = () => {
    this.handleClose();
    this.setState({ walletamount: this.state.walletvalue });
    api
      .get(
        `UseWalletAmount?offerId=&walletUsed=${true}&walletAmount=${
          this.state.walletvalue
        }`
      )
      .then((response) => {
        if (response.StatusCode === 200 && response.Result) {
          this.setState({
            checkoutData: response.Result,
            walletbalance: response.Result.WalletBalance,
            walletamount: response.Result.WalletAmount,
          });
        }
      });
  };

  // handleClickRedeem=()=>{

  //   swal({
  //     title: "Redeem Your Loyalty Points",
  //     content: "input",
  //     inputPlaceholder: "Enter your loyalty points",
  //     button: {
  //       text: "Redeem",
  //       closeModal: false,
  //     },
  //   })
  //   .then((ok) => {
  //     if (ok) {
  //       swal("Points has been successfully redeemed to your account", {
  //         icon: "success",
  //       });
  //     } else {
  //       swal("Something went wrong!",{
  //         icon:"error"
  //       });
  //     }
  //   });
  // }

  handleGetLoyaltyPoints = async () => {
    if (localStorage.getItem("IsLoyaltyEnable") === "true") {
      let cid = localStorage.getItem("userData");
      let customerid = JSON.parse(cid);

      let requestOptions = {
        method: "GET",
        redirect: "follow",
      };

        fetch(`https://ecomuat.loyalty.biotechhealthcare.com/api/CustomerStatus?customerid=${customerid.id}`, requestOptions)
              .then(response => response.json())
              .then(result => {
                  //console.log("LOYALTY POINTS...:", result)
                  this.setState({
                      loyaltypoints: result.data.totalAvailable,
                  })
              }
              )
              .catch(error => console.log('error', error));
      }
  }


  handleLoyaltyRedeemPoints = async () => {
    const { selectedLanguageData } = this.props.app;

    let cid = localStorage.getItem("userData");
    let customerid = JSON.parse(cid);
    let auth = localStorage.getItem("authToken");
    let device = localStorage.getItem("deviceToken");

    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    swal({
      title: languageLable(selectedLanguageData, "Redeem Your Loyalty Points"),
      content: "input",
      //inputPlaceholder: "Enter your loyalty points",
      button: {
        text: languageLable(selectedLanguageData, "Redeem"),
        closeModal: false,
      },
    }).then((ok) => {
      if (ok) {
        fetch(
          `https://ecomuat.loyalty.biotechhealthcare.com/api/RedeemPoints?customerid=${customerid.id}&points=${ok}&authorizetoken=Bearer ${auth}&devicetoken=${device}&baseurl=@http://localhost:7100`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            //54 + ok
            // const currencySetting = localStorage.getItem('currency');
            // var position = 55 + ok.length;
            // var totalLength = result.message.length;
            // var message = result.message.substring(0, position) + `${currencySetting ? currencySetting : "₹"}` + result.message.substring(position, totalLength);
            swal(`${result.message}`, {
              icon: "success",
            }).then((ok) => {
              window.location.reload();
            });
          });
      } else {
        swal(languageLable(selectedLanguageData, "Something went wrong!"), {
          icon: "error",
        });
      }
    });
  };

  render() {
    const { checkoutData } = this.state;
    const { selectedLanguageData } = this.props.app;
    const currencySetting = localStorage.getItem("currency");
    const isLoyaltyEnable = localStorage.getItem("IsLoyaltyEnable");
    const taxNameString = localStorage.getItem("taxNameString");

    const canPayLater =
      this.props.auth &&
      this.props.auth.userData &&
      this.props.auth.userData.can_paylater === "true"
        ? true
        : false;

    return (
      <>
        <div className="ps-checkout ps-section--shopping payment-conformation">
          <div className="ps-container">
            <div className="ps-section__content">
              <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                  <div className="ps-form__orders">
                    <div className="ps-block--checkout-order">
                      <div className="ps-block__content">
                        <figure>
                          <figcaption>
                            <strong>
                              {languageLable(
                                selectedLanguageData,
                                "Bill Details"
                              )}
                            </strong>
                          </figcaption>
                        </figure>
                        <figure>
                          <figcaption>
                            <strong>
                              {languageLable(selectedLanguageData, "Sub Total")}
                            </strong>
                            <strong>
                              {currencySetting ? currencySetting : "₹"}{" "}
                              {checkoutData && checkoutData.SubTotal
                                ? checkoutData.SubTotal.toFixed(2)
                                : 0}
                            </strong>
                          </figcaption>
                        </figure>
                        {checkoutData &&
                        checkoutData.ProductDiscount &&
                        checkoutData.ProductDiscount > 0 ? (
                          <figure>
                            <figcaption>
                              <strong>
                                {languageLable(
                                  selectedLanguageData,
                                  "Product Discount"
                                )}
                              </strong>
                              <strong>
                                ({currencySetting ? currencySetting : "₹"}{" "}
                                {checkoutData && checkoutData.ProductDiscount
                                  ? checkoutData.ProductDiscount
                                  : 0}{" "}
                                )
                              </strong>
                            </figcaption>
                          </figure>
                        ) : (
                          <></>
                        )}
                        {checkoutData &&
                        checkoutData.OfferDiscount &&
                        checkoutData.OfferDiscount > 0 ? (
                          <figure>
                            <figcaption>
                              <strong>
                                {languageLable(
                                  selectedLanguageData,
                                  "Discount / Promo"
                                )}
                              </strong>
                              <strong>
                                ( {currencySetting ? currencySetting : "₹"}{" "}
                                {checkoutData && checkoutData.OfferDiscount
                                  ? checkoutData.OfferDiscount
                                  : 0}{" "}
                                )
                              </strong>
                            </figcaption>
                          </figure>
                        ) : (
                          <></>
                        )}
                        <figure>
                          <figcaption>
                            <strong>
                              {languageLable(
                                selectedLanguageData,
                                "Delivery Charges"
                              )}
                            </strong>
                            <strong>
                              {currencySetting ? currencySetting : "₹"}{" "}
                              {checkoutData && checkoutData.ShippingCharge
                                ? checkoutData.ShippingCharge
                                : 0}
                            </strong>
                          </figcaption>
                        </figure>

                        {isLoyaltyEnable === "true" ? (
                          <div
                            className="ps-block--payment-method"
                            style={{ height: "120px" }}
                          >
                            <div className="col-lg-12">
                              <figure>
                                <figcaption>
                                  <h4>
                                    <strong>
                                      {languageLable(
                                        selectedLanguageData,
                                        "Loyalty"
                                      )}
                                    </strong>
                                  </h4>
                                </figcaption>
                                <div className="row">
                                  <div className="col-lg-8">
                                    {languageLable(
                                      selectedLanguageData,
                                      "Loyalty Points"
                                    )}
                                    : {this.state.loyaltypoints}
                                  </div>
                                  <div className="col-md-4">
                                    <button
                                      className="ps-btn ps-btn--fullwidth"
                                      onClick={() =>
                                        this.handleLoyaltyRedeemPoints()
                                      }
                                      style={{ background: "#2C5662" }}
                                    >
                                      {languageLable(
                                        selectedLanguageData,
                                        "Redeem"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </figure>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        <div
                          className="ps-block--payment-method"
                          style={{ height: "100px" }}
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <figcaption>
                                <h4>
                                  <strong>
                                    {languageLable(
                                      selectedLanguageData,
                                      "Wallet"
                                    )}
                                  </strong>
                                </h4>
                              </figcaption>
                              <div className="row">
                                <div className="col-lg-11">
                                  {this.renderWalletCheckBox()}
                                </div>
                                <div className="col-lg-1">
                                  <IconButton
                                    id={
                                      this.state.walletamount &&
                                      this.state.walletamount
                                        ? this.state.walletamount
                                        : 0
                                    }
                                    onClick={() => this.editWalletAmount()}
                                  >
                                    <img src="/static/img/icons/edit.png" />
                                  </IconButton>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <figure>
                          <figcaption>
                            <strong>
                              {languageLable(
                                selectedLanguageData,
                                " Wallet Deduction"
                              )}
                            </strong>
                            <strong>
                              ( {currencySetting ? currencySetting : "₹"}{" "}
                              {this.state.walletamount &&
                              this.state.walletamount
                                ? this.state.walletamount
                                : 0}{" "}
                              )
                            </strong>
                          </figcaption>
                        </figure>
                        {taxNameString !== "" &&
                        taxNameString !== "undefined" ? (
                          <figure>
                            <figcaption>
                              <strong>
                                {languageLable(
                                  selectedLanguageData,
                                  " Gross Value"
                                )}
                              </strong>
                              <strong>
                                {currencySetting ? currencySetting : "₹"}{" "}
                                {checkoutData && checkoutData.TotalAmount
                                  ? checkoutData.TotalAmount
                                  : 0}{" "}
                              </strong>
                            </figcaption>
                          </figure>
                        ) : (
                          <></>
                        )}
                        {taxNameString !== "" &&
                        taxNameString !== "undefined" ? (
                          <figure>
                            <figcaption>
                              <strong>
                                {languageLable(
                                  selectedLanguageData,
                                  taxNameString
                                )}
                              </strong>
                              <strong>
                                {currencySetting ? currencySetting : "₹"}{" "}
                                {checkoutData && checkoutData.TotalTaxAmount
                                  ? checkoutData.TotalTaxAmount.toFixed(2)
                                  : 0}
                              </strong>
                            </figcaption>
                          </figure>
                        ) : (
                          <></>
                        )}
                        {this.renderToPayAmount()}
                      </div>
                    </div>
                  </div>

                  {/* New Portion End */}

                  {/* New Portion 1 Start */}

                  {checkoutData && checkoutData.OfferId ? (
                    <>
                      <figure>
                        <figcaption>
                          <h4>
                            <strong>
                              {languageLable(
                                selectedLanguageData,
                                "Coupon Discount Remove"
                              )}
                            </strong>
                          </h4>
                        </figcaption>
                        <div
                          className="row"
                          style={{
                            border: "1px solid  #DDDDDD",
                            marginLeft: 0,
                            marginRight: 0,
                            padding: 15,
                          }}
                        >
                          <div className="col-md-8">
                            <img
                              src="/static/img/icons/offer.png"
                              style={{ marginRight: 6, height: 25, width: 30 }}
                            />
                            <span
                              style={{
                                color: "#50CEE5",
                                border: "2px dashed",
                                borderRadius: 8,
                                padding: 4,
                              }}
                            >
                              {languageLable(
                                selectedLanguageData,
                                this.state.couponCodeTemp
                              )}
                            </span>
                            <div
                              style={{
                                fontWeight: "bold",
                                color: "#396470",
                                marginTop: 5,
                                fontSize: 17,
                              }}
                            >
                              {languageLable(
                                selectedLanguageData,
                                this.state.couponTitleTemp
                              )}
                            </div>
                            <div>
                              {languageLable(
                                selectedLanguageData,
                                "Promo code applied successfully"
                              )}
                            </div>
                            {/* <input
                    className="form-control"
                    value={"Coupan Code :" + this.state.coupancode + " Coupan Amount :" + this.state.coupandiscount}
                    disabled
                  /> */}
                          </div>
                          <div className="col-md-4">
                            <button
                              className="ps-btn ps-btn--fullwidth"
                              onClick={(values) =>
                                this.handleCoupanCodeRemove(values)
                              }
                              style={{ background: "#2C5662" }}
                            >
                              {languageLable(selectedLanguageData, "Remove")}
                            </button>
                          </div>
                        </div>
                      </figure>
                    </>
                  ) : (
                    <>
                      <figure>
                        <figcaption>
                          <h4>
                            <strong>
                              {languageLable(
                                selectedLanguageData,
                                "Coupon Discount"
                              )}
                            </strong>
                          </h4>
                        </figcaption>
                        <Form
                          name="coupan-code"
                          onFinish={(values) => this.handleCoupanCode(values)}
                          onFinishFailed={() => this.onFinishFailed()}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <Form.Item name="coupanCode">
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                  className="form-control"
                                >
                                  <Grid xs={6}>
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        color: "#396470",
                                        fontWeight: "bold",
                                        fontSize: 17,
                                      }}
                                    >
                                      <img
                                        src="/static/img/icons/offer.png"
                                        style={{
                                          margin: 3,
                                          height: 22,
                                          width: 26,
                                        }}
                                      />
                                      {languageLable(
                                        selectedLanguageData,
                                        "Apply Coupon Code"
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid xs={6}>
                                    <div
                                      onClick={() =>
                                        this.props.history.push(
                                          "/account/offers"
                                        )
                                      }
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <IconButton>
                                        <ArrowForwardIosIcon
                                          fontSize="25"
                                          style={{
                                            color: "#396470",
                                            fontWeight: "bold",
                                            fontSize: 20,
                                          }}
                                        />
                                      </IconButton>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Form.Item>
                            </div>
                          </div>
                        </Form>
                      </figure>
                    </>
                  )}

                  {/* New Portion 1 End */}

                  <div className="ps-block--shipping">
                    <h4>
                      {languageLable(
                        selectedLanguageData,
                        "How you want to pay?"
                      )}
                    </h4>

                    <div className="ps-block--payment-method">
                      <div className="ps-block__header">
                        {/* New Portion Start */}

                        {
                          <Radio.Group
                            onChange={(e) => this.handleChangePaymentMethod(e)}
                            value={this.state.method}
                          >
                            {canPayLater ? (
                              checkoutData && checkoutData.TotalAmount > 0 ? (
                                this.state.paymentModes &&
                                this.state.paymentModes.length > 0 &&
                                this.state.paymentModes.map((item) => {
                                  return (
                                    <Radio value={item}>
                                      {item === "COD"
                                        ? languageLable(
                                            selectedLanguageData,
                                            "Pay Later"
                                          )
                                        : languageLable(
                                            selectedLanguageData,
                                            "Online"
                                          )}
                                    </Radio>
                                  );
                                })
                              ) : (
                                <Radio value={"Wallet"}>
                                  {languageLable(
                                    selectedLanguageData,
                                    "Wallet"
                                  )}
                                </Radio>
                              )
                            ) : (
                              <Radio value={"Online"}>
                                {languageLable(selectedLanguageData, "Online")}
                              </Radio>
                            )}
                          </Radio.Group>
                        }
                      </div>

                      {this.renderPlaceOrderButton()}
                    </div>

                    <div className="ps-block__footer">
                      <Link to="/account/cart">
                        <i className="icon-arrow-left mr-2"></i>
                        {languageLable(selectedLanguageData, "Return to cart")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*.......................  wallet Edit Amount................................ */}

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
        >
          <div style={{ width: 400 }}>
            <DialogTitle id="form-dialog-title">
              <h3> {languageLable(selectedLanguageData, "Enter Amount")}</h3>
            </DialogTitle>
          </div>
          <DialogContent>
            <TextField
              autoFocus
              id="standard-number"
              type="number"
              value={this.state.walletvalue}
              onChange={(e) => this.handleChangeValue(e)}
              fullWidth
              variant="outlined"
            />
          </DialogContent>
          <p />
          <DialogActions>
            <Button
              style={{ fontSize: 14 }}
              onClick={this.handleClose}
              color="primary"
            >
              {languageLable(selectedLanguageData, "Cancel")}
            </Button>
            <Button
              variant="contained"
              style={{ fontSize: 14 }}
              onClick={() => this.handleOk()}
              color="primary"
            >
              {languageLable(selectedLanguageData, "ok")}
            </Button>
          </DialogActions>
        </Dialog>

        {/* ..................................New Popup.................................... */}

        <Dialog
          open={this.state.open1}
          onClose={this.handleClose1}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
        >
          <div style={{ width: 400 }}>
            <DialogTitle id="form-dialog-title">
              <h3>{languageLable(selectedLanguageData, "Enter Document")}</h3>
              <p>
                (
                {languageLable(
                  selectedLanguageData,
                  "CPF or CNPJ. Send without a dot or dash"
                )}
                .)
              </p>
            </DialogTitle>
          </div>
          <DialogContent>
            <TextField
              autoFocus
              type="text"
              // value={this.state.walletvalue}
              // onChange={(e)=>this.handleChangeValue(e)}
              // error
              onChange={(e) => this.setState({ documentVal: e.target.value })}
              placeholder={languageLable(selectedLanguageData, "Document")}
              // helperText=``
              fullWidth
              variant="outlined"
            />
          </DialogContent>
          <p />
          <DialogActions>
            <Button
              style={{ fontSize: 14 }}
              onClick={this.handleClose1}
              color="primary"
            >
              {languageLable(selectedLanguageData, "Cancel")}
            </Button>
            <Button
              variant="contained"
              style={{ fontSize: 14 }}
              onClick={() => this.handleSubmitDocument()}
              color="primary"
              disabled={this.state.loading}
            >
              {languageLable(selectedLanguageData, "Continue")}
            </Button>
          </DialogActions>
        </Dialog>

        {/*..........................Payment Dialog................................ */}

        <Dialog
          open={this.state.open2}
          onClose={this.handleClose2}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
        >
          {/* <DialogContent > */}
          <iframe
            src={this.state.urlLink}
            style={{ border: "0px solid black", width: 1000, height: 700 }}
          />
          {/* </DialogContent> */}

          <DialogActions>
            <Button
              style={{ fontSize: 15 }}
              onClick={this.handleClose2}
              color="primary"
            >
              {languageLable(selectedLanguageData, "Cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps)(withRouter(Payment));
